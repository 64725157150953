import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Button, Spinner } from 'reactstrap'
import axios from 'axios'

import './index.scss'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import CareCard from '../../components/CareCard/careCard'

import NurseHash from '../../assets/graphics/graphic-nurse.svg'
import DoctorHash from '../../assets/graphics/graphic-doctor.svg'
import FrontlineHash from '../../assets/graphics/graphic-frontline.svg'
import ReachingHash from '../../assets/graphics/graphic-reaching.svg'
import GratefulHash from '../../assets/graphics/graphic-grateful.svg'
import AAPACN from '../../assets/graphics/graphic-aapacn.svg'

const HashtagPage = () => {
	const [cardData, setCardData] = useState([])
	const [tag, setTag] = useState('')
	const [tagInfo, setTagInfo] = useState({})
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		async function getData() {
			try {
				const params = new URLSearchParams(window.location.search)
				const url = `${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}`
				const hashtag = params.get('hashtag')
				setTag(hashtag)
				const tagResponse = await axios.get(`${url}/tags/hashtag/${hashtag.replace(/#/g, '%23')}`)
				const cardResponse = await axios.get(`${url}/cpcs/hashtag/${hashtag.replace(/#/g, '')}`)
				setCardData(cardResponse.data.cpcs)
				setTagInfo(tagResponse.data.tag)
				setIsLoading(false)
			} catch (error) {
				console.error('Error getting card data: ', error)
			}
		}
		getData()
	}, [])

	const cardImageMapping = {
		'#thankanurse': NurseHash,
		'#aapacn': AAPACN,
		'#thankadoctor': DoctorHash,
		'#gratefulpatient': GratefulHash,
		'#reachingourheroes': ReachingHash,
		'#frontlinefeels': FrontlineHash,
	}

	const cardImage = cardImageMapping[tagInfo.hashtag] || GratefulHash

	return (
		<Layout>
			<SEO title='Hashtag' />
			{isLoading ? (
				<div style={{ textAlign: 'center', color: '#8345f8' }}>
					<Spinner animation='grow' role='status' />
				</div>
			) : (
				<Container>
					<Row className='hashtag-row my-5'>
						<Col md={2} className='text-center'>
							<img src={cardImage} alt='' />
						</Col>
						<Col md={10} className='mb-3 px-5'>
							<Row className='hashtag-row-title'>
								{tagInfo.title} {tagInfo.hashtag}
							</Row>
							<Row>
								<span className='hashtag-row-subtitle pt-2'>{tagInfo.desc}</span>
							</Row>
							<Row>
								<Button size='sm' className='hashtag-row-create-button px-3' href={`/create/?hashtag=${tag}`}>
									Post
								</Button>
							</Row>
						</Col>
					</Row>
					<Col>
						<Row className='p-3 justify-content-center'>
							{cardData.length ? (
								cardData.map(post => {
									return (
										<Row key={post.id}>
											<div className='px-2'>
												<CareCard post={post} />
											</div>
										</Row>
									)
								})
							) : (
								<Row className='hashtag-no-results px-4 pb-3'>No cards found.</Row>
							)}
						</Row>
					</Col>
				</Container>
			)}
		</Layout>
	)
}

export default HashtagPage
